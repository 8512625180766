



























import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class BoardEmbed extends Vue {
  finishedLoading: boolean = false;

  mounted() {
    this.finishedLoading = false;
    setTimeout(() => {
      this.finishedLoading = true;
    }, 1500);
  }
}
